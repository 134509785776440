<template>
  <button-base type="secondary-bold-stroke" @click="openChat">
    <slot />
  </button-base>
</template>

<script setup lang="ts">
  const openChat = async (): Promise<void> => {
    window.zE('webWidget', 'show');
    await nextTick();
    window.zE('webWidget', 'open');
  };
</script>
