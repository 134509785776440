<template>
  <vue-final-modal class="modal-cooling-off-limit">
    <div class="vfm__head">
      <div class="vfm__control vfm__control-left" />

      <h2 class="vfm__title modal-cooling-off-limit__title">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'title') }}
      </h2>

      <div class="vfm__control vfm__control-right">
        <atomic-icon id="close" class="vfm__close" @click="closeModal('cooling-off-limit')" />
      </div>
    </div>

    <div class="vfm__body modal-cooling-off-limit__container">
      <atomic-image
        class="modal-cooling-off-limit__image"
        :src="getContent(props.currentLocaleData, props.defaultLocaleData, 'image')"
      />

      <h3 class="modal-cooling-off-limit__subtitle">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'subtitle') }}
      </h3>

      <p class="modal-cooling-off-limit__description">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'description') }}
      </p>

      <cooling-off-limit-timer
        v-if="coolingOfLimit.expiredAt"
        :expired-at="coolingOfLimit.expiredAt"
        :current-locale-data="props.currentLocaleData"
        :default-locale-data="props.defaultLocaleData"
      />

      <p class="modal-cooling-off-limit__hint">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'chatSupportHint') }}
      </p>

      <button-chat-support class="modal-cooling-off-limit__button">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'chatSupportButton') }}
      </button-chat-support>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';
  import type { CICoolingOffLimitModal } from '~/types';

  const { closeModal } = useModalStore();

  const limitsStore = useLimitsStore();
  const { getLimits } = limitsStore;
  const { coolingOffLimits } = storeToRefs(limitsStore);

  await getLimits();

  const coolingOfLimit = computed(() => coolingOffLimits.value[0]);

  const props = defineProps<{
    currentLocaleData: Maybe<CICoolingOffLimitModal>;
    defaultLocaleData: Maybe<CICoolingOffLimitModal>;
  }>();

  const { getContent } = useProjectMethods();
</script>

<style src="~/assets/styles/components/modal/cooling-off-limit.scss" langs="scss"></style>
