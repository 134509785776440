<template>
  <div class="cooling-off-limit-timer">
    <atomic-icon id="expires" />

    <span class="cooling-off-limit-timer__label">
      {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'counterLabel') }}
    </span>

    <atomic-timer :expired-at="props.expiredAt" size="md" />
  </div>
</template>

<script setup lang="ts">
  import type { CICoolingOffLimitModal } from '~/types';

  const props = defineProps<{
    expiredAt: string;
    currentLocaleData: Maybe<CICoolingOffLimitModal>;
    defaultLocaleData: Maybe<CICoolingOffLimitModal>;
  }>();

  const { getContent } = useProjectMethods();
</script>

<style src="~/assets/styles/components/cooling-off-limit/timer.scss" langs="scss" scoped></style>
